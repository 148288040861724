import * as React from 'react'
import cn from 'classnames'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { Tooltip } from 'src/design-system'
import { RequiredSkillsSectionVm } from './required-skills-section-vm'
import { Requirement } from 'store/modules/requirements'
import { SkillLevelIndicator } from 'components/skill-level-indicator'
import { SkillLevelOutcomesSection } from 'components/skill-level-outcomes-section'
import styles from './styles.module.scss'

type RequiredSkillCardProps = {
  onOpenChange: (open: boolean) => void
  requirement: Requirement
  vm: RequiredSkillsSectionVm
}

export const RequiredSkillCard: React.VFC<RequiredSkillCardProps> = (props) => {
  const { onOpenChange, requirement, vm } = props

  if (!requirement.skillVariant) return null
  const description = vm.skillLevelDescription(
    requirement.level,
    requirement.skillVariant.id
  )

  return (
    <div
      className="flex flex-col gap-1 hover:bg-gray-25 p-4 rounded-xl transition-all ease-in-out duration-200"
      key={requirement.id}
    >
      <div className="flex gap-2 items-center justify-between">
        <h4 className="font-bold text-gray-900 text-lg truncate">
          {requirement.skillVariant?.fullName || requirement.skill.name}
        </h4>
        <Tooltip
          side="bottom"
          content={`Level ${requirement.level} of ${requirement.skillVariant.maxLevel}`}
        >
          <SkillLevelIndicator
            level={requirement.level}
            maxLevel={requirement.skillVariant.maxLevel}
          />
        </Tooltip>
      </div>
      {description && (
        <HtmlContent className={cn(styles.htmlContent)}>
          {description}
        </HtmlContent>
      )}
      <SkillLevelOutcomesSection
        onOpenChange={onOpenChange}
        open={vm.open(requirement.id)}
        outcomes={vm.outcomes(requirement.level, requirement.skillVariant.id)}
      />
    </div>
  )
}
